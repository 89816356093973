<template>
	<v-container class="main-container">
		<p>Back</p>
		<h3 class="admin-default-subheader">Course Name</h3>
		<h2 class="admin-default-header">Assign this course to users</h2>
		<h3 class="admin-default-subheader">
			Users and groups assigned for this course
		</h3>

		<div>Name Surname</div>

		<label class="admin-default-label" for="new_user_first_name"
			>Search for Users or Groups</label
		>
		<v-text-field
			id="new_user_first_name"
			name="new_user_first_name"
			type="text"
			class="rounded-0 admin-default-input"
			solo
			rounded
			single-line
			v-model="newUserFirstName"
		></v-text-field>

		<v-btn class="admin-primary-button primary-contrast-background"
			>Assign</v-btn
		>
	</v-container>
</template>

<script>
export default {
	name: "NewCourseAssignUsers"
};
</script>

<style></style>
